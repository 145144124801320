import React, { useContext } from 'react'
import { Link } from 'gatsby'
import LocaleContext from '../context/LocaleContext'
import PageContext from '../context/PageContext'
import { localisePath } from '../domain/locale'
import { INDEX_PATH } from '../domain/path'
import Layout from '../components/layout/Layout'
import Seo from '../components/layout/Seo'

function NotFoundPage() {
    const { locale } = useContext(LocaleContext)

    const path = localisePath(INDEX_PATH, locale)

    return (
        <PageContext.Provider
            value={{
                basepath: INDEX_PATH,
                locale,
                localised: true,
            }}
        >
            <Layout>
                <Seo
                    title="Not Found"
                    titleCompany="Telobag Official"
                    relativeUrl="/404"
                />
                <div className="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
                    <div className="max-w-max mx-auto">
                        <main className="sm:flex">
                            <p className="text-4xl font-extrabold text-ocean-green-600 sm:text-5xl">
                                404
                            </p>
                            <div className="sm:ml-6">
                                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                                    <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                        Page not found
                                    </h1>
                                    <p className="mt-1 text-base text-gray-500">
                                        Please check the URL in the address bar
                                        and try again.
                                    </p>
                                </div>
                                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                                    <Link
                                        to={path}
                                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-ocean-green-600 hover:bg-ocean-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ocean-green-500"
                                    >
                                        Go back home
                                    </Link>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </Layout>
        </PageContext.Provider>
    )
}

export default NotFoundPage
